
import { defineComponent } from "vue";
export default defineComponent({
  name: "PaymentSuccess",
  async mounted() {
    // @todo Thank you page
    // delay for get user or team quota updated
    setTimeout(() => {
      this.$router.push("/account/plan-billing");
    }, 2000);
  },
});
