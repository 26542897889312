import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/image/check_mark.svg'


const _hoisted_1 = { class: "box-card pt-28 pb-28 pl-40 pr-40" }
const _hoisted_2 = {
  class: "fs-12 mb-12",
  style: {"color":"#696c80"}
}
const _hoisted_3 = { class: "flex-center" }
const _hoisted_4 = { class: "fs-16 fw-700 fc-primary" }
const _hoisted_5 = {
  class: "ml-auto fs-12",
  style: {"color":"#696c80"}
}
const _hoisted_6 = {
  key: 0,
  class: ""
}
const _hoisted_7 = {
  key: 1,
  class: ""
}
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { class: "input-with-icon mt-12" }
const _hoisted_10 = { class: "progress" }
const _hoisted_11 = {
  class: "fs-14 mt-14 fw-500",
  style: {"color":"#696c80"}
}
const _hoisted_12 = { class: "fs-14 fw-700 flex mt-22" }
const _hoisted_13 = { class: "box-content mt-8" }
const _hoisted_14 = { class: "grid-2-50" }
const _hoisted_15 = { class: "text-des flex-mid-list" }
const _hoisted_16 = {
  key: 0,
  class: "mt-20",
  style: {"display":"flex"}
}
const _hoisted_17 = { class: "fs-12 fw-500" }
const _hoisted_18 = { class: "font-pay plan-hint flex-mid" }
const _hoisted_19 = { class: "grid-2-pand-adn-billing" }
const _hoisted_20 = { class: "left-link" }
const _hoisted_21 = {
  class: "box-card pt-28 pb-28 pl-40 pr-40 mt-32",
  style: {"background-color":"#fff"}
}
const _hoisted_22 = { style: {"display":"flex","flex-direction":"row"} }
const _hoisted_23 = { style: {"display":"flex","flex-direction":"column"} }
const _hoisted_24 = {
  class: "fs-12 mb-12",
  style: {"color":"#696c80","text-transform":"uppercase"}
}
const _hoisted_25 = {
  key: 0,
  style: {"display":"flex","flex-direction":"column","margin-left":"auto"}
}
const _hoisted_26 = {
  class: "input-container-02",
  style: {"min-width":"162px"}
}
const _hoisted_27 = {
  key: 0,
  class: "flex-center mt-28"
}
const _hoisted_28 = { class: "fs-12 fw-500" }
const _hoisted_29 = { class: "flex-center mt-28" }
const _hoisted_30 = {
  key: 0,
  width: "3rem",
  height: "3rem",
  version: "1.1",
  id: "L9",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 100 100",
  "enable-background": "new 0 0 0 0",
  "xml:space": "preserve"
}
const _hoisted_31 = { class: "fs-12 fw-500" }
const _hoisted_32 = {
  key: 1,
  class: "flex-center mt-28",
  style: {"display":"flex","flex-direction":"column","gap":"12px","align-items":"start"}
}
const _hoisted_33 = {
  class: "box-card p-24 mt-32",
  style: {"background-color":"#fff","display":"none","min-height":"198px"}
}
const _hoisted_34 = {
  key: 0,
  class: "fs-12 mb-12",
  style: {"color":"#696c80"}
}
const _hoisted_35 = {
  key: 1,
  class: "fs-12 mb-12",
  style: {"color":"#696c80"}
}
const _hoisted_36 = {
  key: 2,
  class: "fs-12 mb-12",
  style: {"color":"#696c80"}
}
const _hoisted_37 = {
  key: 3,
  class: "fs-12 mb-12",
  style: {"color":"#696c80"}
}
const _hoisted_38 = {
  key: 4,
  class: "fs-16 fw-700 fc-primary mb-16"
}
const _hoisted_39 = {
  key: 5,
  class: "fs-16 fw-700 fc-primary mb-16"
}
const _hoisted_40 = { class: "fs-14 mb-16 fw-400" }
const _hoisted_41 = { class: "fw-700" }
const _hoisted_42 = { class: "flex-center" }
const _hoisted_43 = { class: "fs-12 fw-500" }
const _hoisted_44 = { class: "fs-12 fw-500" }
const _hoisted_45 = { class: "fs-12 fw-500" }
const _hoisted_46 = { class: "fs-12 fw-500" }
const _hoisted_47 = { class: "relative" }
const _hoisted_48 = {
  key: 0,
  class: "font-pay"
}
const _hoisted_49 = { class: "right-link" }
const _hoisted_50 = {
  class: "box-card pt-28 pb-28 pl-40 pr-40 mt-32",
  style: {"background-color":"#fff"}
}
const _hoisted_51 = { style: {"display":"flex","flex-direction":"row"} }
const _hoisted_52 = { style: {"display":"flex","flex-direction":"column"} }
const _hoisted_53 = {
  class: "fs-12 mb-12",
  style: {"color":"#696c80","text-transform":"uppercase"}
}
const _hoisted_54 = {
  key: 0,
  style: {"display":"flex","flex-direction":"column","margin-left":"auto"}
}
const _hoisted_55 = {
  class: "input-container-02",
  style: {"min-width":"162px"}
}
const _hoisted_56 = { class: "flex-center mt-28" }
const _hoisted_57 = {
  key: 0,
  width: "3rem",
  height: "3rem",
  version: "1.1",
  id: "L9",
  xmlns: "http://www.w3.org/2000/svg",
  "xmlns:xlink": "http://www.w3.org/1999/xlink",
  x: "0px",
  y: "0px",
  viewBox: "0 0 100 100",
  "enable-background": "new 0 0 0 0",
  "xml:space": "preserve"
}
const _hoisted_58 = { class: "fs-12 fw-500" }
const _hoisted_59 = {
  key: 0,
  class: "font-pay plan-hint"
}
const _hoisted_60 = {
  class: "grid-2-pand-adn-billing",
  style: {"margin-bottom":"72px"}
}
const _hoisted_61 = { class: "left-link" }
const _hoisted_62 = {
  class: "box-card pt-28 pb-28 pl-40 pr-40 mt-32",
  style: {"background-color":"#fff"}
}
const _hoisted_63 = {
  class: "fs-12 mb-12",
  style: {"color":"#696c80"}
}
const _hoisted_64 = { class: "fs-16 fw-700 fc-primary mb-16" }
const _hoisted_65 = {
  class: "fs-14",
  style: {"color":"#696c80"}
}
const _hoisted_66 = { class: "" }
const _hoisted_67 = { class: "fw-700" }
const _hoisted_68 = {
  href: "mailto:sales@happily.ai",
  style: {"color":"#30ccb4","text-decoration":"none"}
}
const _hoisted_69 = { class: "flex-center mt-28" }
const _hoisted_70 = {
  class: "input-container-02",
  style: {"width":"100%"}
}
const _hoisted_71 = ["placeholder"]
const _hoisted_72 = {
  for: "",
  class: "label"
}
const _hoisted_73 = { class: "fs-12 fw-500" }
const _hoisted_74 = { class: "relative" }
const _hoisted_75 = {
  key: 0,
  class: "font-pay abosulute"
}
const _hoisted_76 = { class: "remove-header remove-padding-modal" }
const _hoisted_77 = { class: "pb-42 pl-32 pr-24 fc-1B1C1E" }
const _hoisted_78 = { class: "fs-18 fw-700" }
const _hoisted_79 = {
  class: "mt-15 fw-400 mb-38",
  style: {"word-break":"normal"}
}
const _hoisted_80 = { class: "input-container-02" }
const _hoisted_81 = { key: 0 }
const _hoisted_82 = { class: "fs-12 fw-500" }
const _hoisted_83 = { class: "fs-12 fw-500" }
const _hoisted_84 = { class: "fs-12 fw-500" }
const _hoisted_85 = { class: "fs-12 fw-500" }
const _hoisted_86 = { class: "credit-rows" }
const _hoisted_87 = { class: "credit-row" }
const _hoisted_88 = { class: "labels-group" }
const _hoisted_89 = { class: "title" }
const _hoisted_90 = { class: "desc" }
const _hoisted_91 = { class: "fs-12 fw-500" }
const _hoisted_92 = { class: "credit-row" }
const _hoisted_93 = { class: "labels-group" }
const _hoisted_94 = { class: "title" }
const _hoisted_95 = { class: "desc" }
const _hoisted_96 = { class: "fs-12 fw-500" }
const _hoisted_97 = { class: "credit-row" }
const _hoisted_98 = { class: "labels-group" }
const _hoisted_99 = { class: "title" }
const _hoisted_100 = { class: "desc" }
const _hoisted_101 = { class: "fs-12 fw-500" }
const _hoisted_102 = {
  class: "mb-20 flex-mid m-auto",
  style: {"margin-top":"6.2rem","width":"36rem","display":"none"}
}
const _hoisted_103 = {
  class: "input-with-icon",
  style: {"width":"23rem"}
}
const _hoisted_104 = ["placeholder"]
const _hoisted_105 = { class: "fs-16 fw-700" }
const _hoisted_106 = { class: "remove-header remove-padding-modal" }
const _hoisted_107 = { class: "pb-42 pt-42 pl-32 pr-32 fc-1B1C1E" }
const _hoisted_108 = { class: "title-monthly" }
const _hoisted_109 = {
  class: "subtitle-mothly mt-20",
  style: {"margin-bottom":"5.6rem"}
}
const _hoisted_110 = { class: "fs-12 fw-500" }
const _hoisted_111 = { class: "sub-btn" }
const _hoisted_112 = { class: "fs-12 fw-500" }
const _hoisted_113 = { class: "fs-12 fw-500" }
const _hoisted_114 = { class: "sub-btn" }
const _hoisted_115 = { class: "fs-12 fw-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_progress = _resolveComponent("el-progress")!
  const _component_custom_select = _resolveComponent("custom-select")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("myAccount.planAndBill.Your_current_plan")), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.currentPlan), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (
            _ctx.yourPlan == 'Monthly Subscription' ||
            _ctx.yourPlan == 'Annual Subscription'
          )
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t("myAccount.planAndBill.Unlimited_credits")), 1))
            : (_ctx.yourPlan == 'Unlimited')
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.credit) + "/" + _toDisplayString(_ctx.$t("myAccount.planAndBill.Unlimited_credits_used")), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.credit) + "/" + _toDisplayString(_ctx.creditTotal) + " " + _toDisplayString(_ctx.$t("myAccount.planAndBill.credits_used")), 1))
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_el_progress, {
            percentage: _ctx.percentage,
            "show-text": false,
            color: _ctx.color,
            "stroke-width": 2
          }, null, 8, ["percentage", "color"])
        ])
      ]),
      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t("myAccount.planAndBill.A_credit_gets_used")) + " " + _toDisplayString(_ctx.$t("myAccount.planAndBill.You_must_have_available")), 1),
      _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("myAccount.planAndBill.All_plans_have_access_to_the_same_benefit")), 1),
      _cache[27] || (_cache[27] = _createElementVNode("form", {
        id: "checkoutForm",
        class: "mt-32",
        method: "POST",
        action: "/charge",
        style: {"display":"none"}
      }, [
        _createElementVNode("input", {
          type: "hidden",
          name: "omiseToken"
        }),
        _createElementVNode("input", {
          type: "hidden",
          name: "omiseSource"
        }),
        _createElementVNode("input", {
          type: "hidden",
          name: "amount"
        }),
        _createElementVNode("input", {
          type: "hidden",
          name: "currency"
        }),
        _createElementVNode("input", {
          type: "hidden",
          name: "description"
        }),
        _createElementVNode("input", {
          type: "hidden",
          name: "id"
        }),
        _createElementVNode("input", {
          type: "hidden",
          name: "redirectUri"
        }),
        _createElementVNode("button", {
          type: "submit",
          id: "checkoutButton"
        }, "Pay with Omise")
      ], -1)),
      _cache[28] || (_cache[28] = _createElementVNode("form", {
        id: "checkoutFormStripe",
        class: "mt-32",
        method: "POST",
        style: {"display":"none"}
      }, [
        _createElementVNode("input", {
          type: "hidden",
          name: "email"
        }),
        _createElementVNode("input", {
          type: "hidden",
          name: "team_id"
        }),
        _createElementVNode("input", {
          type: "hidden",
          name: "price"
        }),
        _createElementVNode("input", {
          type: "hidden",
          name: "success_url"
        }),
        _createElementVNode("input", {
          type: "hidden",
          name: "cancel_url"
        }),
        _createElementVNode("button", {
          type: "submit",
          id: "checkoutButtonStripe"
        }, "Pay with Stripe")
      ], -1)),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listItemPay, (value, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: 'text_pen' + index,
              class: "value-container"
            }, [
              _cache[26] || (_cache[26] = _createElementVNode("div", { class: "checkbox flex-center" }, [
                _createElementVNode("svg", {
                  width: "16",
                  height: "17",
                  viewBox: "0 0 16 17",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }, [
                  _createElementVNode("path", {
                    d: "M6.24265 10.6126L12.3709 4.48438L13.3137 5.42718L6.24265 12.4982L2 8.25563L2.94281 7.31283L6.24265 10.6126Z",
                    fill: "#22BC66"
                  })
                ])
              ], -1)),
              _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t("myAccount.planAndBill." + value.name)), 1)
            ]))
          }), 128))
        ]),
        (_ctx.canUnsubscribe())
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createElementVNode("div", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.unsubscribe && _ctx.unsubscribe(...args))),
                class: "btn02 btn-48 btn-primary flex-mid",
                style: {"width":"92px"}
              }, [
                _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t(
                "myAccount.planAndBill." +
                  (_ctx.unsubscribed ? `Unsubscribed` : `Unsubscribe`)
              )), 1)
              ]),
              _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t("myAccount.planAndBill.Automatically_renews_on_date", {
            date: this.getExpiredDateLabel(),
          })), 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("myAccount.planAndBill.Upgrade_to")), 1)
              ]),
              _cache[29] || (_cache[29] = _createElementVNode("div", { class: "fs-16 fw-700 fc-primary mb-16" }, _toDisplayString("Growth"), -1))
            ]),
            false
              ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                  _createElementVNode("div", _hoisted_26, [
                    _createVNode(_component_custom_select, {
                      ref: "currencyOptions",
                      value: _ctx.currencySelected,
                      options: _ctx.currencyOptions,
                      "x-placeholder": _ctx.$t('createAccount.Choose_language'),
                      "x-label": _ctx.$t('createAccount.Choose_language'),
                      onInput: _ctx.handleCurrency,
                      disableAll: true
                    }, null, 8, ["value", "options", "x-placeholder", "x-label", "onInput"])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _cache[37] || (_cache[37] = _createStaticVNode("<div class=\"plan fs-14 mb-16\" data-v-5af1efee><span class=\"plan-title\" data-v-5af1efee>Scale your hiring with confidence</span><div class=\"plan-list\" data-v-5af1efee><div class=\"plan-list-item\" data-v-5af1efee><div class=\"checkbox flex-center\" data-v-5af1efee><img src=\"" + _imports_0 + "\" data-v-5af1efee></div> 100 candidate credits annually</div><div class=\"plan-list-item\" data-v-5af1efee><div class=\"checkbox flex-center\" data-v-5af1efee><img src=\"" + _imports_0 + "\" data-v-5af1efee></div> 24-month credit rollover</div><div class=\"plan-list-item\" data-v-5af1efee><div class=\"checkbox flex-center\" data-v-5af1efee><img src=\"" + _imports_0 + "\" data-v-5af1efee></div> Bulk Invites</div><div class=\"plan-list-item\" data-v-5af1efee><div class=\"checkbox flex-center\" data-v-5af1efee><img src=\"" + _imports_0 + "\" data-v-5af1efee></div> Standard support</div></div></div>", 1)),
          false
            ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                _createElementVNode("div", {
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openGrowthPlanModalStatusDetail && _ctx.openGrowthPlanModalStatusDetail(...args))),
                  class: _normalizeClass(["btn02 btn-48 btn-primary flex-mid", `${_ctx.isUnlimited ? 'disable-true' : ''}`]),
                  style: {"min-width":"7rem"}
                }, [
                  _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.buyPaygCreditLabel), 1)
                ], 2),
                _cache[30] || (_cache[30] = _createElementVNode("span", { class: "font-pay plan-hint" }, _toDisplayString("100 Credits"), -1))
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("div", {
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openGrowthPlanModalStatusDetail && _ctx.openGrowthPlanModalStatusDetail(...args))),
              class: _normalizeClass(["btn02 btn-48 btn-primary flex-mid", `${_ctx.growthPlanButtonCls}`]),
              style: {"min-width":"7rem"}
            }, [
              true
                ? (_openBlock(), _createElementBlock("svg", _hoisted_30, _cache[31] || (_cache[31] = [
                    _createElementVNode("path", {
                      fill: "#aaa",
                      d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                    }, [
                      _createElementVNode("animateTransform", {
                        attributeName: "transform",
                        attributeType: "XML",
                        type: "rotate",
                        dur: "1s",
                        from: "0 50 50",
                        to: "360 50 50",
                        repeatCount: "indefinite"
                      })
                    ], -1)
                  ])))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.buyPaygCreditLabel), 1)
            ], 2),
            _cache[32] || (_cache[32] = _createElementVNode("span", { class: "font-pay plan-hint" }, _toDisplayString("100 Credits"), -1))
          ]),
          false
            ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                _cache[36] || (_cache[36] = _createElementVNode("span", { class: "fs-12 fw-500" }, "Testing", -1)),
                _createElementVNode("div", {
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.resetPayment && _ctx.resetPayment(...args))),
                  class: "btn02 btn-48 btn-primary flex-mid",
                  style: {"min-width":"7rem"}
                }, _cache[33] || (_cache[33] = [
                  _createElementVNode("span", { class: "fs-12 fw-500" }, _toDisplayString(`Reset Payment`), -1)
                ])),
                _createElementVNode("div", {
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.resetQuota && _ctx.resetQuota(...args))),
                  class: "btn02 btn-48 btn-primary flex-mid",
                  style: {"min-width":"7rem"}
                }, _cache[34] || (_cache[34] = [
                  _createElementVNode("span", { class: "fs-12 fw-500" }, _toDisplayString(`Reset Quota`), -1)
                ])),
                _createElementVNode("div", {
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.expireQuota && _ctx.expireQuota(...args))),
                  class: _normalizeClass(["btn02 btn-48 btn-primary flex-mid", `${!_ctx.isUnlimited ? 'disable-true' : ''}`]),
                  style: {"min-width":"7rem"}
                }, _cache[35] || (_cache[35] = [
                  _createElementVNode("span", { class: "fs-12 fw-500" }, _toDisplayString(`Expire Quota`), -1)
                ]), 2)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_33, [
          (_ctx.yourPlan && _ctx.yourPlan == 'Pay as you go')
            ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("myAccount.planAndBill.Upgrade_to")), 1)
              ]))
            : (_ctx.yourPlan && _ctx.yourPlan == 'Monthly Subscription')
              ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("myAccount.planAndBill.Manage_your_plan")), 1)
                ]))
              : (_ctx.yourPlan && _ctx.yourPlan == 'Annual Subscription')
                ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("myAccount.planAndBill.Manage_your_plan")), 1)
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_37, _toDisplayString(_ctx.$t("myAccount.planAndBill.Upgrade_to")), 1)),
          (_ctx.yourPlan && _ctx.yourPlan == 'Annual Subscription')
            ? (_openBlock(), _createElementBlock("div", _hoisted_38, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("myAccount.planAndBill.Annual_Subscriptionogo")), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_39, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("myAccount.planAndBill.Monthly_Subscription")), 1)
              ])),
          _createElementVNode("div", _hoisted_40, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("myAccount.planAndBill.For_businesses_wiht_high")), 1),
            _createElementVNode("span", _hoisted_41, _toDisplayString(_ctx.$t("myAccount.planAndBill._49_per_month")), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("myAccount.planAndBill.for_unlimited_credits")), 1)
          ]),
          _createElementVNode("div", _hoisted_42, [
            (_ctx.yourPlan && _ctx.yourPlan == 'Pay as you go')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.openMonthlySubscriptionModalStatusDetail && _ctx.openMonthlySubscriptionModalStatusDetail(...args))),
                  class: "btn02 btn-48 btn-primary flex-mid",
                  style: {"min-width":"7rem"}
                }, [
                  _createElementVNode("span", _hoisted_43, _toDisplayString(_ctx.$t("myAccount.planAndBill.Subscribe")), 1)
                ]))
              : (_ctx.yourPlan && _ctx.yourPlan == 'Monthly Subscription')
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.subscribeAnnual && _ctx.subscribeAnnual(...args))),
                    class: "btn02 btn-48 btn-primary flex-mid",
                    style: {"min-width":"7rem"}
                  }, [
                    _createElementVNode("span", _hoisted_44, _toDisplayString(_ctx.$t("myAccount.planAndBill.Change_to_Annual")), 1)
                  ]))
                : (_ctx.yourPlan && _ctx.yourPlan == 'Annual Subscription')
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.subscribeMonthly && _ctx.subscribeMonthly(...args))),
                      class: "btn02 btn-48 btn-primary flex-mid",
                      style: {"min-width":"7rem"}
                    }, [
                      _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.$t("myAccount.planAndBill.Change_to_Monthly")), 1)
                    ]))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 3,
                      onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.openMonthlySubscriptionModalStatusDetail && _ctx.openMonthlySubscriptionModalStatusDetail(...args))),
                      class: "btn02 btn-48 btn-primary flex-mid",
                      style: {"min-width":"7rem"}
                    }, [
                      _createElementVNode("span", _hoisted_46, _toDisplayString(_ctx.$t("myAccount.planAndBill.Subscribe")), 1)
                    ])),
            _createElementVNode("div", _hoisted_47, [
              (_ctx.yourPlan != 'Annual Subscription')
                ? (_openBlock(), _createElementBlock("span", _hoisted_48, _toDisplayString(_ctx.$t("myAccount.planAndBill.Select_the_annual_plan")), 1))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_49, [
        _createElementVNode("div", _hoisted_50, [
          _createElementVNode("div", _hoisted_51, [
            _createElementVNode("div", _hoisted_52, [
              _createElementVNode("div", _hoisted_53, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("myAccount.planAndBill.Upgrade_to")), 1)
              ]),
              _cache[38] || (_cache[38] = _createElementVNode("div", { class: "fs-16 fw-700 fc-primary mb-16" }, _toDisplayString("Business Plus"), -1))
            ]),
            false
              ? (_openBlock(), _createElementBlock("div", _hoisted_54, [
                  _createElementVNode("div", _hoisted_55, [
                    _createVNode(_component_custom_select, {
                      ref: "currencyOptions2",
                      value: _ctx.currencySelected2,
                      options: _ctx.currencyOptions,
                      "x-placeholder": _ctx.$t('createAccount.Choose_language'),
                      "x-label": _ctx.$t('createAccount.Choose_language'),
                      onInput: _ctx.handleCurrency2,
                      disableAll: true
                    }, null, 8, ["value", "options", "x-placeholder", "x-label", "onInput"])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _cache[40] || (_cache[40] = _createStaticVNode("<div class=\"plan fs-14 mb-16\" data-v-5af1efee><span class=\"plan-title\" data-v-5af1efee>Includes Growth features, plus:</span><div class=\"plan-list\" data-v-5af1efee><div class=\"plan-list-item\" data-v-5af1efee><div class=\"checkbox flex-center\" data-v-5af1efee><img src=\"" + _imports_0 + "\" data-v-5af1efee></div> Unlimited candidate assessments</div><div class=\"plan-list-item\" data-v-5af1efee><div class=\"checkbox flex-center\" data-v-5af1efee><img src=\"" + _imports_0 + "\" data-v-5af1efee></div> ATS Integration</div><div class=\"plan-list-item\" data-v-5af1efee><div class=\"checkbox flex-center\" data-v-5af1efee><img src=\"" + _imports_0 + "\" data-v-5af1efee></div> Priority support</div><div class=\"plan-list-item\" data-v-5af1efee><div class=\"checkbox flex-center\" data-v-5af1efee><img src=\"" + _imports_0 + "\" data-v-5af1efee></div> API access</div></div></div>", 1)),
          _createElementVNode("div", _hoisted_56, [
            _createElementVNode("div", {
              onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.openBusinessPlusPlanModalStatusDetail && _ctx.openBusinessPlusPlanModalStatusDetail(...args))),
              class: _normalizeClass(["btn02 btn-48 btn-primary flex-mid", `${_ctx.businessPlusPlanButtonCls}`]),
              style: {"min-width":"7rem"}
            }, [
              true
                ? (_openBlock(), _createElementBlock("svg", _hoisted_57, _cache[39] || (_cache[39] = [
                    _createElementVNode("path", {
                      fill: "#aaa",
                      d: "M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                    }, [
                      _createElementVNode("animateTransform", {
                        attributeName: "transform",
                        attributeType: "XML",
                        type: "rotate",
                        dur: "1s",
                        from: "0 50 50",
                        to: "360 50 50",
                        repeatCount: "indefinite"
                      })
                    ], -1)
                  ])))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_58, _toDisplayString(_ctx.buyUnlimitedCreditLabel), 1)
            ], 2),
            (_ctx.yourPlan)
              ? (_openBlock(), _createElementBlock("span", _hoisted_59, _toDisplayString("Unlimited credits")))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_60, [
      _createElementVNode("div", _hoisted_61, [
        _createElementVNode("div", _hoisted_62, [
          _createElementVNode("div", _hoisted_63, _toDisplayString(_ctx.$t("myAccount.planAndBill.Coupon")), 1),
          _createElementVNode("div", _hoisted_64, _toDisplayString(_ctx.$t("myAccount.planAndBill.Redeem_Free_Credits")), 1),
          _createElementVNode("div", _hoisted_65, [
            _createElementVNode("span", _hoisted_66, _toDisplayString(_ctx.$t("myAccount.planAndBill.Enter_your_coupon")), 1),
            _createElementVNode("span", _hoisted_67, [
              _createElementVNode("a", _hoisted_68, _toDisplayString(_ctx.$t("myAccount.planAndBill.sales_happily_ai")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_69, [
            _createElementVNode("div", _hoisted_70, [
              _withDirectives(_createElementVNode("input", {
                class: _normalizeClass(["input", `${_ctx.redeemCouponFailed ? 'validate' : ''}`]),
                type: "email",
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.couponCode) = $event)),
                placeholder: _ctx.$t('placeholder.Enter_coupon_code')
              }, null, 10, _hoisted_71), [
                [_vModelText, _ctx.couponCode]
              ]),
              _createElementVNode("label", _hoisted_72, _toDisplayString(_ctx.$t("placeholder.Code")), 1)
            ]),
            _createElementVNode("div", {
              onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.redeemCoupon && _ctx.redeemCoupon(...args))),
              class: "btn02 btn-48 btn-primary flex-mid ml-16",
              style: {"min-width":"7rem"}
            }, [
              _createElementVNode("span", _hoisted_73, _toDisplayString(_ctx.$t("myAccount.planAndBill.Redeem")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_74, [
            (_ctx.redeemCouponFailed && _ctx.couponCode)
              ? (_openBlock(), _createElementBlock("span", _hoisted_75, _toDisplayString(_ctx.$t("myAccount.planAndBill.We_re_sorry")), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _cache[41] || (_cache[41] = _createElementVNode("div", { class: "right-link" }, null, -1))
    ]),
    _createElementVNode("div", _hoisted_76, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.payAsYouGoModalStatus,
        "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.payAsYouGoModalStatus) = $event)),
        width: "560px",
        ref: "payAsYouGoModalModal"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_77, [
            _createElementVNode("div", _hoisted_78, _toDisplayString(_ctx.$t("myAccount.planAndBill.PAY_AS_YOU_GO")), 1),
            _createElementVNode("div", _hoisted_79, _toDisplayString(_ctx.$t("myAccount.planAndBill.Available_credits_allow_you")), 1),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_80, [
                _createVNode(_component_custom_select, {
                  ref: "currencyOptions",
                  value: _ctx.currencySelected,
                  options: _ctx.currencyOptions,
                  "x-placeholder": _ctx.$t('createAccount.Choose_language'),
                  "x-label": _ctx.$t('createAccount.Choose_language'),
                  onInput: _ctx.handleCurrency,
                  disableAll: true
                }, null, 8, ["value", "options", "x-placeholder", "x-label", "onInput"])
              ])
            ]),
            false
              ? (_openBlock(), _createElementBlock("div", _hoisted_81, [
                  _createElementVNode("div", {
                    onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.payAsYouGo('PAYG_10_1000'))),
                    class: "btn02 btn-48 btn-primary flex-mid btn-payg mb-20"
                  }, [
                    _createElementVNode("span", _hoisted_82, _toDisplayString(_ctx.$t("myAccount.planAndBill.Buy_10_for_S10")), 1)
                  ]),
                  _createElementVNode("div", {
                    onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.payAsYouGo('PAYG_20_1800'))),
                    class: "btn02 btn-48 btn-primary flex-mid btn-payg mb-20 mt-20"
                  }, [
                    _createElementVNode("span", _hoisted_83, _toDisplayString(_ctx.$t("myAccount.planAndBill.Buy_20_for_S18")), 1)
                  ]),
                  _createElementVNode("div", {
                    onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.payAsYouGo('PAYG_30_2400'))),
                    class: "btn02 btn-48 btn-primary flex-mid btn-payg mb-20 mt-20"
                  }, [
                    _createElementVNode("span", _hoisted_84, _toDisplayString(_ctx.$t("myAccount.planAndBill.Buy_30_for_S24")), 1)
                  ]),
                  _createElementVNode("div", {
                    onClick: _cache[16] || (_cache[16] = ($event: any) => (_ctx.payAsYouGo('PAYG_40_2800'))),
                    class: "btn02 btn-48 btn-primary flex-mid btn-payg mb-20 mt-20"
                  }, [
                    _createElementVNode("span", _hoisted_85, _toDisplayString(_ctx.$t("myAccount.planAndBill.Buy_40_for_S28")), 1)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_86, [
              _createElementVNode("div", _hoisted_87, [
                _createElementVNode("div", _hoisted_88, [
                  _createElementVNode("div", _hoisted_89, _toDisplayString(_ctx.$t("myAccount.planAndBill.Buy_n_credits", { credits: 10 })), 1),
                  _createElementVNode("div", _hoisted_90, _toDisplayString(_ctx.$t("myAccount.planAndBill.Price_per_credits", {
                    currency: _ctx.currency,
                    price: _ctx.avg_10_credits,
                  })), 1)
                ]),
                _createElementVNode("div", {
                  onClick: _cache[17] || (_cache[17] = ($event: any) => (_ctx.payAsYouGo(`PAYG_10_${_ctx.sum_10_credits}00`))),
                  class: "btn02 btn-48 btn-primary flex-mid btn-payg mb-20"
                }, [
                  _createElementVNode("span", _hoisted_91, _toDisplayString(_ctx.$t("myAccount.planAndBill.Price_with_currency", {
                  currency: _ctx.currency,
                  price: _ctx.sum_10_credits,
                })), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_92, [
                _createElementVNode("div", _hoisted_93, [
                  _createElementVNode("div", _hoisted_94, _toDisplayString(_ctx.$t("myAccount.planAndBill.Buy_n_credits_discount", {
                    credits: 20,
                    discount: 10,
                  })), 1),
                  _createElementVNode("div", _hoisted_95, _toDisplayString(_ctx.$t("myAccount.planAndBill.Price_per_credits", {
                    currency: _ctx.currency,
                    price: _ctx.avg_20_credits,
                  })), 1)
                ]),
                _createElementVNode("div", {
                  onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.payAsYouGo(`PAYG_20_${_ctx.sum_20_credits}00`))),
                  class: "btn02 btn-48 btn-primary flex-mid btn-payg mb-20"
                }, [
                  _createElementVNode("span", _hoisted_96, _toDisplayString(_ctx.$t("myAccount.planAndBill.Price_with_currency", {
                  currency: _ctx.currency,
                  price: _ctx.sum_20_credits,
                })), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_97, [
                _createElementVNode("div", _hoisted_98, [
                  _createElementVNode("div", _hoisted_99, _toDisplayString(_ctx.$t("myAccount.planAndBill.Buy_n_credits_discount", {
                    credits: 50,
                    discount: 30,
                  })), 1),
                  _createElementVNode("div", _hoisted_100, _toDisplayString(_ctx.$t("myAccount.planAndBill.Price_per_credits", {
                    currency: _ctx.currency,
                    price: _ctx.avg_50_credits,
                  })), 1)
                ]),
                _createElementVNode("div", {
                  onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.payAsYouGo(`PAYG_50_${_ctx.sum_50_credits}00`))),
                  class: "btn02 btn-48 btn-primary flex-mid btn-payg mb-20"
                }, [
                  _createElementVNode("span", _hoisted_101, _toDisplayString(_ctx.$t("myAccount.planAndBill.Price_with_currency", {
                  currency: _ctx.currency,
                  price: Number(_ctx.sum_50_credits).toLocaleString("en-US"),
                })), 1)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_102, [
              _createElementVNode("div", _hoisted_103, [
                _withDirectives(_createElementVNode("input", {
                  class: "input input-44",
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.couponCode) = $event)),
                  type: "text",
                  placeholder: _ctx.$t('placeholder.Coupon_code')
                }, null, 8, _hoisted_104), [
                  [_vModelText, _ctx.couponCode]
                ])
              ]),
              _createElementVNode("div", {
                onClick: _cache[21] || (_cache[21] = 
//@ts-ignore
(...args) => (_ctx.redeemCoupon && _ctx.redeemCoupon(...args))),
                class: "btn02 btn-48 btn-primary flex-mid ml-12",
                style: {"height":"4.2rem","width":"8rem"}
              }, [
                _createElementVNode("span", _hoisted_105, _toDisplayString(_ctx.$t("myAccount.planAndBill.Redeem")), 1)
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_106, [
      _createVNode(_component_el_dialog, {
        modelValue: _ctx.monthlySubscriptionModalStatus,
        "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.monthlySubscriptionModalStatus) = $event)),
        width: "560px"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_107, [
            _createElementVNode("div", _hoisted_108, _toDisplayString(_ctx.$t("myAccount.planAndBill.SUBSCRIPTION")), 1),
            _createElementVNode("div", _hoisted_109, _toDisplayString(_ctx.$t("myAccount.planAndBill.Access_unlimited_credits_during")), 1),
            _createElementVNode("div", {
              onClick: _cache[23] || (_cache[23] = 
//@ts-ignore
(...args) => (_ctx.subscribeMonthly && _ctx.subscribeMonthly(...args))),
              class: "btn02 btn-48 btn-primary flex-mid btn-monthly"
            }, [
              _createElementVNode("span", _hoisted_110, _toDisplayString(_ctx.$t("myAccount.planAndBill.Monthly_for_S49")), 1)
            ]),
            _createElementVNode("div", _hoisted_111, [
              _createElementVNode("span", _hoisted_112, _toDisplayString(_ctx.$t("myAccount.planAndBill.Billed_today_and_on")), 1)
            ]),
            _createElementVNode("div", {
              onClick: _cache[24] || (_cache[24] = 
//@ts-ignore
(...args) => (_ctx.subscribeAnnual && _ctx.subscribeAnnual(...args))),
              class: "btn02 btn-48 btn-primary flex-mid btn-monthly"
            }, [
              _createElementVNode("span", _hoisted_113, _toDisplayString(_ctx.$t("myAccount.planAndBill.Annual_for_S441")), 1)
            ]),
            _createElementVNode("div", _hoisted_114, [
              _createElementVNode("span", _hoisted_115, _toDisplayString(_ctx.$t("myAccount.planAndBill.Billed_today_and_next")), 1)
            ]),
            _cache[42] || (_cache[42] = _createElementVNode("div", { class: "pt-14 pb-14" }, null, -1))
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ])
  ], 64))
}